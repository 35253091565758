import React, { Component } from 'react';

// vendor components & lib
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ServiceBot from 'components/service-bot';

// components
import Layout from 'components/common/layout';
import Header from 'components/common/header';

// constants
import {
  SUBSCRIBE_PLAN_PRO,
  SUBSCRIBE_PLAN_ESSENTIAL,
} from 'constants/servicebot_plans';

// styles
import 'stylesheets/post-purchase/index.scss';

class PostPurchasePage extends Component {
  state = {
    isLoading: true,
    userPlanSlug: null,
  };

  componentDidMount() {
    ServiceBot.on('auth:change', this.handleSubscribedStateChange);

    this.handleSubscribedStateChange(ServiceBot.credentials);
  }

  componentWillUnmount() {
    ServiceBot.off('auth:change', this.handleSubscribedStateChange);
  }

  handleSubscribedStateChange = async credential => {
    if (credential === undefined) {
      return;
    }

    const userPlan = ServiceBot.getCurrentUserPlan();

    const userPlanSlug = (userPlan || {}).slug || null;

    this.setState({ userPlanSlug, isLoading: false });
  };

  getFeatures() {
    const {
      requiredReadingImage,
      podcastImage,
      partnerPortalImage,
      officeHoursProImage,
    } = this.props.data;

    const { userPlanSlug } = this.state;

    const FEATURES = {
      [SUBSCRIBE_PLAN_ESSENTIAL]: [
        {
          title: 'Required Reading',
          description:
            'Weekly content walking you through business management essentials',
          image: requiredReadingImage,
          linkUrl: '/resource-library/category/required-reading',
        },
        {
          title: 'Podcast',
          description:
            'With new episodes every Tuesday, Influencer Business features expert guests from across the professional landscape',
          image: podcastImage,
          linkUrl: '/resource-library/category/podcast-recaps',
        },
        {
          title: 'Partner Portal',
          description:
            "You're one-stop shop for all the best resources you need to build, run and grow your business.",
          image: partnerPortalImage,
          linkUrl: '/partners',
        },
      ],
      [SUBSCRIBE_PLAN_PRO]: [
        {
          title: 'Podcast',
          description:
            'With new episodes every Tuesday, Influencer Business features expert guests from across the professional landscape',
          image: podcastImage,
          linkUrl: '/resource-library/category/podcast-recaps',
        },
        {
          title: 'Office Hours',
          description:
            'A weekly webinar hosted by our CEO and answering questions you submit, make sure you register!',
          image: officeHoursProImage,
          linkUrl: '/office-hours',
        },
        {
          title: 'Partner Portal',
          description:
            "You're one-stop shop for all the best resources you need to build, run and grow your business.",
          image: partnerPortalImage,
          linkUrl: '/partners',
        },
      ],
    };

    return FEATURES[userPlanSlug];
  }

  render() {
    const { isLoading, userPlanSlug } = this.state;

    if (isLoading) {
      return (
        <Layout>
          <Header styleType="dark" showLogo />
        </Layout>
      );
    }

    if (!userPlanSlug) {
      return (
        <Layout>
          <Header styleType="dark" showLogo />
          <h4 className="mt-3 text-center">
            You don't have access to view this page
          </h4>
        </Layout>
      );
    }

    const {
      postsAvailableForPro,
      postsAvailableForEssential,
    } = this.props.data;

    const readNextPosts =
      userPlanSlug === SUBSCRIBE_PLAN_PRO
        ? postsAvailableForPro
        : postsAvailableForEssential;
    const readNextPostsEdges = (readNextPosts || {}).edges || [];

    const features = this.getFeatures();

    return (
      <Layout>
        <Header styleType="dark" showLogo />
        <main className="post-purchase">
          <div className="post-purchase_message">
            <div className="post-purchase_message_content">
              <h2 className="post-purchase_message_title">You’re in!</h2>
              <p className="post-purchase_message_description">
                Welcome to Trove and thank you for joining our community.
              </p>
              <p className="post-purchase_message_description post-purchase_message_description--large">
                <span className="post-purchase_message_description_mark">
                  Remember:
                </span>{' '}
                Trove exists for you. If there is a topic you need covered, or a
                question you need answered, please don’t hesitate to{' '}
                <Link to="/contact-us/">get in touch</Link>.
              </p>
            </div>
          </div>
          <div className="post-purchase-content">
            <h1 className="post-purchase_title">Your Trove Features</h1>

            <div className="post-purchase_features-list">
              {(features || []).map(
                ({ title, description, image, linkUrl }, index) => (
                  <Feature
                    key={index}
                    title={title}
                    description={description}
                    image={image}
                    linkUrl={linkUrl}
                  />
                ),
              )}
            </div>

            <h2 className="post-purchase_read-next_title">Read next</h2>
            <div className="post-purchase_read-next_list">
              {readNextPostsEdges.map(({ node }) => (
                <ReadNextPost key={node.id} post={node} />
              ))}
            </div>
          </div>
        </main>
      </Layout>
    );
  }
}

function ReadNextPost({ post }) {
  const { categories, title, headerImage, slug, description } = post || {};
  const image = (headerImage || {}).fluid;

  const descriptionExcerpt = ((description || {}).childMarkdownRemark || {})
    .excerpt;
  const linkUrl = `/resource-library/${slug}`;

  return (
    <div className="post-purchase_read-next_wrap">
      <Link to={linkUrl} className="post-purchase_read-next_post">
        {image && (
          <Img fluid={image} className="post-purchase_read-next_image" />
        )}

        <div className="post-purchase_read-next_gradient" />

        <div className="post-purchase_read-next_post_content">
          <div className="d-flex justify-content-between">
            <div className="post-purchase_read-next_tags-container">
              {(categories || []).map(category => (
                <div
                  key={category.slug}
                  className="post-purchase_read-next_tag"
                >
                  {(category || {}).name}
                </div>
              ))}
            </div>
          </div>

          <span className="post-purchase_read-next_name">{title}</span>
          <div className="post-purchase_read-next_description">
            {descriptionExcerpt}
          </div>
        </div>
      </Link>
    </div>
  );
}

function Feature({ title, description, image, linkUrl }) {
  const featureImage = ((image || {}).childImageSharp || {}).fluid;

  return (
    <Link to={linkUrl} className="post-purchase_feature">
      <h3 className="post-purchase_feature_title">{title}</h3>
      <p className="post-purchase_feature_description">{description}</p>

      {featureImage && (
        <Img className="post-purchase_feature_image" fluid={featureImage} />
      )}
    </Link>
  );
}

export const pageQuery = graphql`
  query {
    requiredReadingImage: file(
      relativePath: { eq: "feature-required-reading.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 726) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    podcastImage: file(relativePath: { eq: "feature-podcast.png" }) {
      childImageSharp {
        fluid(maxWidth: 726) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    partnerPortalImage: file(
      relativePath: { eq: "feature-partner-portal.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 726) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    requiredReadingProImage: file(
      relativePath: { eq: "feature-required-reading-pro.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 726) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    officeHoursProImage: file(
      relativePath: { eq: "feature-office-hours.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 726) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beyondTheBasicsImage: file(
      relativePath: { eq: "feature-beyond-the-basics.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 726) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    postsAvailableForEssential: allContentfulResourceLibrary(
      sort: { fields: publishDate, order: DESC }
      filter: { subscribePlan: { slug: { ne: "pro" } } }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          slug
          subscribePlan {
            slug
          }
          categories {
            name
            slug
          }
          description {
            childMarkdownRemark {
              excerpt
            }
          }
          headerImage {
            fluid(maxWidth: 726) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }

    postsAvailableForPro: allContentfulResourceLibrary(
      sort: { fields: publishDate, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          slug
          subscribePlan {
            slug
          }
          categories {
            name
            slug
          }
          description {
            childMarkdownRemark {
              excerpt
            }
          }
          headerImage {
            fluid(maxWidth: 726) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`;

export default PostPurchasePage;
